html, body {
  height: 100vh;
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
}

body, p, h1, h2, h3 {
  margin: 0;
}

$proximaLight: Proxima-Nova-Light;
$proximaRegular: Proxima-Nova-Light;
$proximaBold: Proxima-Nova-Bold;
$proximaSemiBold: Proxima-Nova-Semi-Bold;

$color-error: #EF4050;
$color-orange: #EE820F;
$color-blue: #004284;
$color-grey: #353639;
$color-light-grey: #787D8A;
$color-bg: #FAFBFC;
$color-blue-dark: #003366;
$color-blue-dark-bg: #00264D;
$color-green: #8db800;

// Chip styling

$onboarding-color: #9BA0A4;
$onboarding-background: rgba(235, 238, 239);
$purple-chip: #7F0099;
$purple-background: rgba(232, 216, 238);
$green-chip: #7FB022;
$green-background: rgba(232, 240, 222);
$dark-green-chip: #009954;
$dark-green-background: rgba(215, 236, 229);
$dark-blue-chip: #004C99;
$dark-blue-background: rgba(215, 226, 238);
$red-chip: #EE0F0F;
$red-background: rgba(247, 218, 220);


// Font imports
@font-face {
  font-family: Proxima-Nova-Light;
  src: url(/assets/fonts/ProximaNova-Light.otf);
}

@font-face {
  font-family: Proxima-Nova-Bold;
  src: url(/assets/fonts/ProximaNova-Bold.otf);
}

@font-face {
  font-family: Proxima-Nova-Regular;
  src: url(/assets/fonts/ProximaNova-Regular.otf);
}

@font-face {
  font-family: Proxima-Nova-Semi-Bold;
  src: url(/assets/fonts/ProximaNova-Sbold.otf);
}


.mat-ink-bar {
  background-color: $color-blue !important;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: #000;
    opacity: 0.50 !important;
  }
}

.mat-snack-bar-container{
  background-color: white;
  border-color: white !important;
  text-align: center;
  &.error {
    color: #EE0F0F;

    .mat-simple-snackbar {
      font-family: $proximaSemiBold;
    }
  }

  &.success {
    color: $color-green;

    .mat-simple-snackbar {
      font-family: $proximaSemiBold;
    }
  }
}
